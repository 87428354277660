import React from 'react';
import PropTypes from 'prop-types';

import HeroContainer from '../../elements/HeroContainer';
import AboutUsContainer from '../../elements/HotelElements/AboutUsContainer';
import MaskedElement from '../../elements/HotelElements/MaskedElement';
import ImageContainer from '../../elements/ImageContainer';
import ServicesContainer from '../../elements/ServicesContainer';
import GalleryContainer from '../../elements/GalleryContainer';
import ContactFrom from '../../elements/ContactForm';

const HotelSanaSection = (props) => (
  <>
    <HeroContainer data={props.data.heroComponent} pages={props.global.pages} />
    <AboutUsContainer data={props.data.aboutUs} />
    <MaskedElement data={props.data.aboutContainer} />
    <ImageContainer data={props.data.imageContainer} />
    <ServicesContainer data={props.data.servicesContainer} />
    <GalleryContainer data={props.data.galleryComponent} />
    <ContactFrom data={props.data.contactForm} />
  </>
);

HotelSanaSection.propTypes = {
  global: PropTypes.shape({
    pages: PropTypes.arrayOf(
      PropTypes.shape({ links: PropTypes.string.isRequired }),
    ).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    heroComponent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    aboutUs: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      leftContent: PropTypes.string.isRequired,
      rightContent: PropTypes.string.isRequired,
      rightDescription: PropTypes.string.isRequired,
    }).isRequired,
    aboutContainer: PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
    imageContainer: PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    servicesContainer: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
    galleryComponent: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    contactForm: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      placeholderName: PropTypes.string.isRequired,
      placeholderEmail: PropTypes.string.isRequired,
      placeholderSubject: PropTypes.string.isRequired,
      placeholderMessage: PropTypes.string.isRequired,
      buttonSuccess: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default HotelSanaSection;
