import React from 'react';
import PropTypes from 'prop-types';

const AboutUsHeader = (props) => (
  <div className="container-wrapper text-gray-400">
    <div className="header-wrapper m-0 mt-8 lg:mt-28">
      <h1 className="header-text">{props.data.header}</h1>
      <p className="title-text about-title text-black">{props.data.title}</p>
    </div>
    <p className="lg:mt-16">{props.data.description}</p>
    <p className="lg:my-8 my-4">{props.data.leftContent}</p>
  </div>
);

AboutUsHeader.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    leftContent: PropTypes.string.isRequired,
  }).isRequired,
  imagesData: PropTypes.shape({
    firstImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    secondImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AboutUsHeader;
