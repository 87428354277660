import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './companyNavArrow.scss';

const SliderArrow = () => (
  <div role="presentation" className="custom-slider-arrows">
    <FaChevronDown className="arrow-top slider-arrow" />
    <FaChevronDown className="arrow-middle slider-arrow" />
    <FaChevronDown className="arrow-bottom slider-arrow" />
  </div>
);

export default SliderArrow;
