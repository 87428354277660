import React from 'react';
import PropTypes from 'prop-types';

const ImageContainer = (props) => (
  <div className="lg:m-36 m-8 lg:grid lg:grid-cols-2 lg:gap-x-28 flex flex-col-reverse">
    <div className="lg:flex lg:flex-col lg:justify-center">
      <h1 className="text-black text-lg leading-8 font-bold lg:mb-16 mt-4 lg:mt-0">
        {props.data.title}
      </h1>
      <p className="text-black lg:text-lg text-base leading-8 mt-4 lg:mt-0">
        {props.data.description}
      </p>
    </div>
    <div>
      <img
        src={props.data.image.url}
        alt={props.data.title}
        className="rounded-2xl rounded-bl-100px mb-8 lg:mb-0"
      />
    </div>
  </div>
);

ImageContainer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ImageContainer;
