/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import NextArrow from './SliderArrow/NextArrow';
import PrevArrow from './SliderArrow/PrevArrow';
import SliderCard from './SliderCard';

import './homeSlider.scss';

const HomeSlider = (props) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="home-slider-wrapper" id="poslovne-jedinice">
      <div className="header-wrapper">
        <h1 className="header-text">{props.data.header}</h1>
        <p className="title-text about-title">{props.data.title}</p>
        <p className="description-text mb-0 lg:mb-16">
          {props.data.description}
        </p>
      </div>
      <div className="home-slider-container lg:flex lg:items-center lg:justify-center lg:mb-12">
        <div className="lg:mx-16 xl:mx-36 overflow-hidden">
          <Slider {...settings}>
            {props.data.slider.map((item) => (
              <SliderCard
                key={item.title}
                title={item.title}
                buttonUrl={item.url}
                image={item.image?.url}
                description={item.description}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

HomeSlider.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slider: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default HomeSlider;
