import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './maskedElement.scss';
import '../../AboutContainer';

const MaskedElement = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="relative masked-wrapper-second">
      <div className="container-wrapper lg:flex lg:justify-between lg:items-center">
        <div className="masked-element-second w-full lg:mt-28">
          <img
            className="masked-image-second"
            src={props.data.image.url}
            alt="about-us"
          />
        </div>
        <div className="lg:flex lg:flex-col lg:justify-center xl:ml-44 lg:ml-20 lg:items-start lg:h-screen">
          <h1 className="lg:w-4/5 w-full text-3xl lg:text-3xl font-bold mb-4 lg:mb-12 about-description text-primary">
            {props.data.title}
          </h1>
          <p className="lg:w-4/5 w-full lg:text-base leading-8 mb-4 lg:mb-12 text-white">
            {props.data.description}
          </p>
          <p
            className={`lg:w-4/5 w-full lg:text-xl mb-8 lg:mb-0 font-bold text-white ${
              isReadMore ? 'read-more' : 'read-less'
            }`}
          >
            {props.data.content}
          </p>
        </div>
        <span
          onClick={toggleReadMore}
          role="presentation"
          className="lg:hidden block mb-8 ml-0 text-sm text-primary font-bold tracking-wide container-wrapper"
        >
          OPŠIRNIJE
        </span>
      </div>
    </div>
  );
};

MaskedElement.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default MaskedElement;
