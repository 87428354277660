export const borderRadiuses = {
  1: '70px 70px 15px 15px',
  2: '70px 15px 70px 15px',
  3: '15px 15px 70px 70px',
  4: '15px 70px 15px 70px',
  5: '15px 15px 15px 70px',
  6: '70px 70px 70px 15px',
  7: '15px 70px 70px 15px',
  8: '15px 70px 70px 70px',
};

export const icons = {
  1: 'https://res.cloudinary.com/seed-doo/image/upload/v1640259838/firstIcon_qyqs31.svg',
  2: 'https://res.cloudinary.com/seed-doo/image/upload/v1640259928/secondIcon_okecmf.svg',
  3: 'https://res.cloudinary.com/seed-doo/image/upload/v1640259964/thirdIcon_ugqgag.svg',
};
