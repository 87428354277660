/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
import React from 'react';
import PropTypes from 'prop-types';

import Navbar from './elements/Navbar';
import Footer from './elements/Footer';

const Layout = ({ children, global, pageContext }) => {
  const { navbar, footer, pages } = global;
  const updatedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, { global: { pages } }),
  );
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Navbar data={navbar} pagesData={pages} pageContext={pageContext} />
      <div>{updatedChildren}</div>
      <Footer
        data={footer}
        navbarData={navbar}
        pagesData={pages}
        pageContext={pageContext}
      />
    </div>
  );
};

Layout.propTypes = {
  global: PropTypes.shape({
    navbar: PropTypes.shape({
      logo: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
      button: PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    }).isRequired,
    footer: PropTypes.shape({
      logo: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      facebookUrl: PropTypes.string.isRequired,
      instagramUrl: PropTypes.string.isRequired,
      twitterUrl: PropTypes.string.isRequired,
    }),
    pages: PropTypes.shape({
      links: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};
export default Layout;
