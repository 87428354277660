/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { useForm } from 'react-hook-form';

const Select = ({ options }) => {
  const { register } = useForm();

  return (
    <select
      className="bg-white subject-select mb-4 lg:mb-8 p-4 lg:p-5 text-sm
      border placeholder-black
      focus:outline-none focus:ring-2 w-full
      focus:ring-primary focus:border-transparent"
      {...register('subject', {
        required: { value: true },
      })}
    >
      {options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default Select;
