/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import GalleryCard from './GalleryCard';
import SliderDots from './SliderDots';

const GalleryContainer = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb ',
    beforeChange: (current, next) => setActiveSlide(next),
    customPaging: (i) => (
      <SliderDots sliderIndex={i} activeSlide={activeSlide} />
    ),
  };
  return (
    <div className="container-wrapper lg:mb-20 lg:mt-20 mt-8 mb-16">
      <Slider {...settings}>
        {props.data.images.map((item) => (
          <GalleryCard key={item.image.url} image={item.image.url} />
        ))}
      </Slider>
    </div>
  );
};

GalleryContainer.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default GalleryContainer;
