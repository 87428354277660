import React from 'react';
import PropTypes from 'prop-types';

const GalleryCard = (props) => (
  <div className="lg:h-60vh">
    <img
      src={props.image}
      alt="gallery-img"
      className="object-cover rounded-xl h-full w-full"
    />
  </div>
);

GalleryCard.propTypes = {
  image: PropTypes.string.isRequired,
};

export default GalleryCard;
