/* eslint-disable no-console */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import BurgerMenu from './BurgerMenu';

const Navbar = ({ data, pagesData }) => {
  const [open, setOpen] = useState(false);
  const showMenu = () => {
    setOpen(!open);
  };

  return (
    <div className="lg:h-20vh h-90px bg-darkBlue relative">
      <nav className="container-wrapper lg:flex lg:justify-between lg:items-center lg:mt-8 lg:w-11/12 xl:w-4/5">
        <div className="flex justify-between items-center mt-6">
          <Link to="/">
            <img
              src={data.logo.url}
              alt="logo"
              className="lg:w-44 xl:w-56 w-28"
            />
          </Link>
          <BurgerMenu onToggle={showMenu} />
        </div>
        <ul className="lg:flex lg:justify-around hidden lg:mt-0">
          {data.links.map((item) => (
            <li
              key={item.url}
              className="xl:mr-8 lg:mr-3 mb-5 mt-5 font-medium text-white uppercase"
            >
              <Link activeClassName="text-primary" to={item.url}>
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
        <Link to={data.button.url} className="btn-primary hidden lg:block">
          <p className="w-full font-medium">{data.button.text}</p>
        </Link>
      </nav>
      <nav className="nav-menu" style={{ right: open ? '0' : '-100%' }}>
        <div className="flex justify-between items-center mx-8 mt-6">
          <Link to="/">
            <img src={data.logo.url} alt="logo" className="w-28" />
          </Link>
          <BurgerMenu onToggle={showMenu} isMenuOpen={open} />
        </div>
        <ul className="mt-12 mx-8">
          {data.links.map((item) => (
            <li
              key={item.url}
              className="mb-5 mt-5 font-medium text-white uppercase"
            >
              <Link
                onClick={showMenu}
                activeClassName="text-primary"
                to={item.url}
              >
                {item.text}
              </Link>
            </li>
          ))}
          <hr className="block w-full border-t-1 border-gray-400" />
          {pagesData.links.map((item) => (
            <li key={item.url} className="mb-5 mt-5">
              <Link
                activeClassName="text-primary"
                className="font-medium text-white"
                to={item.url}
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    button: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  }).isRequired,
  pagesData: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default Navbar;
