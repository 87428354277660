import React from 'react';
import PropTypes from 'prop-types';

import './sliderDots.scss';

const SliderDots = ({ sliderIndex, activeSlide }) => (
  <div
    className={`slider-dot${sliderIndex === activeSlide ? '--active' : ''}`}
  />
);

SliderDots.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  sliderIndex: PropTypes.number.isRequired,
};

export default SliderDots;
