import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './sliderCard.scss';

// eslint-disable-next-line object-curly-newline
const SliderCard = (props) => (
  <div className="slide--wrapper">
    <div className="slide-content--wrapper">
      <h1 className="text-black lg:mb-16">{props.title}</h1>
      <p className="text-black lg:mb-16 lg:mt-8">{props.description}</p>
    </div>
    <div className="slide-image--wrapper mx-8 lg:mx-0">
      <Link to={props.buttonUrl}>
        <img src={props.image} alt={props.title} />
      </Link>
    </div>
  </div>
);

SliderCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SliderCard;
