import React from 'react';
import PropTypes from 'prop-types';

import AboutUsHeader from '../../elements/AboutUsElements/AboutUsHeader';
import AboutUsDetails from '../../elements/AboutUsElements/AboutUsDetails';

const AboutUsSection = (props) => (
  <>
    <AboutUsHeader
      data={props.data.aboutUsHeader}
      imagesData={props.data.images}
    />
    <AboutUsDetails data={props.data.aboutUsDetails} />
    <div className="container-wrapper">
      <div className="lg:flex lg:mb-16 mb-8">
        <img
          src={props.data.images.firstImage.url}
          className="lg:rounded-30px rounded-xl rounded-bl-40px lg:rounded-bl-100px lg:w-1/2 w-full lg:h-96 mb-8 lg:mr-16"
          alt="about-us-first"
        />
        <img
          src={props.data.images.secondImage.url}
          className="lg:rounded-30px rounded-xl lg:w-1/2 w-full lg:h-96"
          alt="about-us-second"
        />
      </div>
      <img
        src={props.data.aboutUsDetails.image.url}
        alt="about-us"
        className="lg:h-400px w-full object-cover lg:my-16 my-8"
      />
    </div>
  </>
);

AboutUsSection.propTypes = {
  data: PropTypes.shape({
    aboutUsHeader: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      leftContent: PropTypes.string.isRequired,
    }).isRequired,
    aboutUsDetails: PropTypes.shape({
      description: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    images: PropTypes.shape({
      firstImage: PropTypes.string.isRequired,
      secondImage: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AboutUsSection;
