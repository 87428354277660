import React from 'react';
import PropTypes from 'prop-types';

import '../navbar.scss';

const BurgerMenu = ({ onToggle, isMenuOpen }) => (
  <div className="lg:hidden block z-10 cursor-pointer">
    <div
      className={`navicon ${isMenuOpen && 'close'}`}
      role="presentation"
      onClick={onToggle}
    >
      <span className="top" />
      <span className="middle" />
      <span className="bottom" />
    </div>
  </div>
);

BurgerMenu.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool,
};

BurgerMenu.defaultProps = {
  isMenuOpen: false,
};

export default BurgerMenu;
