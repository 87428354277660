/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const WelcomeContainer = (props) => (
  <div className="header-wrapper">
    <h1 className="header-text">{props.data.header}</h1>
    <p className="title-text about-title font-bold lg:mb-20">
      {props.data.title}
    </p>
    <p className="font-bold">{props.data.unitName}</p>
    <p className="mt-1">{props.data.unitAddress}</p>
    <div dangerouslySetInnerHTML={{ __html: props.data.unitLed }} />
    <div dangerouslySetInnerHTML={{ __html: props.data.workHours }} />
    <div dangerouslySetInnerHTML={{ __html: props.data.phone }} />
    {props.data.email && <p className="mt-1">{props.data.email}</p>}
  </div>
);

WelcomeContainer.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    unitAddress: PropTypes.string.isRequired,
    unitLed: PropTypes.string.isRequired,
    workHours: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default WelcomeContainer;
