/* eslint-disable react/no-children-prop */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

const AboutUsDetails = (props) => (
  <div className="container-wrapper text-gray-400 whitespace-pre-wrap">
    <ReactMarkdown children={props.data.description} />

    <ReactMarkdown className="lg:mb-16 mb-8" children={props.data.content} />
  </div>
);

AboutUsDetails.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AboutUsDetails;
