import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PotpisSeed from '../../../images/potpis_seed.png';

import './about.scss';

const AboutContainer = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <div className="header-wrapper">
        <h1 className="header-text">{props.data.header}</h1>
        <p className="title-text about-title">{props.data.title}</p>
      </div>
      <div className="mt-4 lg:mt-16 mx-8 md:mx-16 lg:mx-56 xl:mx-80 mb-8 lg:mb-32 lg:text-center">
        <p className="lg:text-lg text-base mb-4">
          Domaća, građevinska kompanija Seed d.o.o. sa glavnim sjedištem u
          Sanskom Mostu prisutna je na bh tržištu od 1996. godine. Početna ideja
          te osnovna djelatnost firme i danas jeste trgovina građevinskim
          materijalima.
        </p>
        <p className="lg:text-lg text-base mb-4">
          Uloženo je dosta entuzijazma, vremena, truda, znanja, kapitala i
          energije u razvoj preduzeća i njegove svestrane djelatnosti.
        </p>
        <p className="lg:text-lg text-base mb-4">
          Seed je danas jedan od izuzetnih, pouzdanih partnera, ponajviše pri
          izgradnji i uređenju stambenih prostora, ali i pri savjetovanju i
          kupovini adekvatnih materijala po pristupačnim cijenama.
        </p>
        <p className="lg:text-lg text-base mb-4">
          SEED kompanija je prepoznatljiva po stručnosti i kvaliteti poslovanja
          širom BiH, danas ponosno broji gotovo 50 uposlenika različitih profila
          sa tendencijom daljnjeg širenja i rasta.
        </p>
        <p className="lg:text-lg text-base mb-4">
          Pozivamo Vas da upoznate naše poslovne jedinice, projekte i usluge.
        </p>
        <p className="lg:text-lg text-base mb-4">
          Pozivamo Vas da budućnost pišete s nama.
        </p>
        <img
          src={PotpisSeed}
          alt="potpis-seed"
          className="w-48 md:w-64 mx-auto mt-6 mb-4"
        />
        <p className="text-sm mb-4 text-right">Sanski Most, Aug., 2021.</p>
      </div>
      <div className="relative masked-wrapper">
        <div className="container-wrapper lg:flex lg:justify-between lg:items-center">
          <div className="masked-element w-full">
            <img
              className="masked-image"
              src={props.aboutData.image.url}
              alt="about-us"
            />
          </div>
          <div className="lg:flex lg:flex-col lg:justify-center xl:ml-8 lg:ml-20 lg:items-start lg:h-screen">
            <h1 className="lg:w-4/5 w-full text-3xl mb-4 font-bold lg:mb-12 about-description">
              {props.aboutData.title}
            </h1>
            <p className="lg:w-4/5 w-full lg:text-base mb-4 lg:mb-12">
              {props.aboutData.description}
            </p>
            <div className={`${isReadMore ? 'read-more' : 'read-less'}`}>
              <p className="lg:w-4/5 w-full lg:text-xl mb-4 font-bold">
                {props.aboutData.content}
              </p>
              {props.aboutData.yearTitle && (
                <div className="lg:flex flex lg:mt-14 mb-8 lg:mb-0 lg:w-4/5">
                  <div className="lg:mr-16 mr-4">
                    <h1 className="lg:text-5xl text-2xl about-description">
                      {props.aboutData.year}
                    </h1>
                    <p className="lg:text-xl lg:mt-4 font-bold">
                      {props.aboutData.yearTitle}
                    </p>
                    <p className="lg:text-sm lg:mt-2">
                      {props.aboutData.yearDescription}
                    </p>
                  </div>
                  <div className="lg:mr-14 hidden lg:block">
                    <h1 className="text-white lg:text-8xl">/</h1>
                  </div>
                  <div className="lg:mr-14 mr-4">
                    <h1 className="lg:text-5xl text-2xl about-description">
                      {props.aboutData.unit}
                    </h1>
                    <p className="lg:text-xl lg:mt-4 font-bold">
                      {props.aboutData.unitTitle}
                    </p>
                    <p className="lg:text-sm lg:mt-2">
                      {props.aboutData.unitDescription}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <span
              onClick={toggleReadMore}
              role="presentation"
              className="lg:hidden block mb-8 ml-0 text-sm text-white font-bold tracking-wide container-wrapper"
            >
              OPŠIRNIJE
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

AboutContainer.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    leftContent: PropTypes.string.isRequired,
    rightContent: PropTypes.string.isRequired,
    rightDescription: PropTypes.string.isRequired,
  }).isRequired,
  aboutData: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    yearTitle: PropTypes.string.isRequired,
    yearDescription: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    unitTitle: PropTypes.string.isRequired,
    unitDescription: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutContainer;
