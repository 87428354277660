import React from 'react';
import PropTypes from 'prop-types';

import MemberCard from './MemberCard';

import './ourTeam.scss';

const OurTeam = (props) => (
  <>
    <div className="header-wrapper" id="team">
      <h1 className="header-text">{props.data.header}</h1>
      <p className="title-text font-bold ourTeam-title">{props.data.title}</p>
      <p className="description-text">{props.data.description}</p>
    </div>
    <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 lg:place-items-center container-wrapper md:gap-x-12 lg:gap-x-6 xl:gap-x-0">
      {props.data.teamMember.map((item, i) => (
        <MemberCard
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.name}${i}`}
          name={item.name}
          position={item.position}
          phone={item.phone}
          email={item.email}
          image={item.image.url}
        />
      ))}
    </div>
  </>
);

OurTeam.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    teamMember: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default OurTeam;
