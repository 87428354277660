import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { VscChevronRight } from 'react-icons/vsc';

import CompanyNavArrow from './CompanyNavArrow';

import './navigation.scss';

const CompanyNavigation = (props) => (
  <div className="company-nav-wrapper lg:absolute lg:bottom-20 hidden lg:block">
    <div className="lg:flex lg:justify-between bg-white m-auto rounded-lg">
      {props.data.links.map((item) => (
        <Link
          activeClassName="active-link"
          key={item.text}
          className="company-nav-item"
          to={item.url}
        >
          <div className="xl:pl-3 lg:pl-3 xl:pr-3">
            <p className="text-black font-bold nav-title">{item.text}</p>
            <p className="detail-text flex items-center nav-title justify-start text-sm mt-2">
              Detaljnije
              <VscChevronRight />
            </p>
            <div className="lg:flex lg:justify-center">
              <CompanyNavArrow />
            </div>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

CompanyNavigation.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default CompanyNavigation;
