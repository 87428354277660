/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../../AboutContainer/about.scss';

const AboutUsContainer = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      <div className="header-wrapper">
        <h1 className="header-text">{props.data.header}</h1>
        <p className="title-text about-title">{props.data.title}</p>
        <p className="description-text">{props.data.description}</p>
      </div>
      <div
        className={`lg:grid lg:grid-cols-2 container-wrapper lg:gap-20 lg:mt-16 lg:mb-32 ${
          isReadMore ? 'read-more' : 'read-less'
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: props.data.leftContent }} />
        <div dangerouslySetInnerHTML={{ __html: props.data.rightContent }} />
      </div>
      <span
        onClick={toggleReadMore}
        role="presentation"
        className="lg:hidden block mb-8 mt-4 text-sm text-primary font-bold tracking-wide container-wrapper"
      >
        OPŠIRNIJE
      </span>
    </>
  );
};

AboutUsContainer.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    leftContent: PropTypes.string.isRequired,
    rightContent: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutUsContainer;
