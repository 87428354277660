/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import SeedSection from './sections/SeedSection/index';
import HotelSanaSection from './sections/HotelSanaSection';
import BetonaraSection from './sections/BetonaraSection';
import AboutUsSection from './sections/AboutUsSection';
import SamplePageSection from './sections/SamplePageSection';

const sectionComponents = {
  'sections.seed-section': SeedSection,
  'sections.hotel-sana': HotelSanaSection,
  'sections.betonara-section': BetonaraSection,
  'sections.about-us-section': AboutUsSection,
  'sections.sample-page': SamplePageSection,
};

const Section = ({ sectionData, global }) => {
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component];

  if (!SectionComponent) {
    return null;
  }

  return <SectionComponent data={sectionData} global={global} />;
};

const Sections = ({ sections, global }) => (
  <div className="flex flex-col">
    {sections.map((section, i) => (
      <Section
        global={global}
        sectionData={section}
        key={`${section.strapi_component}${(section.id, i)}`}
      />
    ))}
  </div>
);
Section.propTypes = {
  sectionData: PropTypes.shape({
    strapi_component: PropTypes.string.isRequired,
    __component: PropTypes.string,
  }).isRequired,
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  ).isRequired,
};

export default Sections;
