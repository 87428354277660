import React from 'react';
import PropTypes from 'prop-types';
import { VscChevronRight } from 'react-icons/vsc';
import { Link } from 'gatsby';

const ServicesContainer = (props) => (
  <div className="container-wrapper lg:mb-16 mt-8">
    <p className="header-text lg:mb-8">{props.data.header}</p>
    <div className="lg:grid lg:grid-cols-2 lg:gap-x-28">
      <div>
        <p className="title-text lg:m-0 font-bold lg:text-4xl">
          {props.data.title}
        </p>
      </div>
      <div>
        <p className="description-text lg:w-full lg:m-0">
          {props.data.description}
        </p>
        <p className="mt-3 mb-6">{props.data.content}</p>
        <Link
          to="https://hotelsana.ba"
          className="font-bold about-description flex items-center detail-text text-black"
        >
          {props.data.link}
          <VscChevronRight />
        </Link>
      </div>
    </div>
  </div>
);

ServicesContainer.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default ServicesContainer;
