import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CompanyNavigation from '../CompanyNavigation';

const HeroContainer = (props) => (
  <div className="bg-darkBlue lg:h-80vh lg:grid lg:grid-cols-2 xl:pl-36 lg:pl-16 relative overflow-hidden">
    <div className="flex flex-col justify-center text-center items-center lg:items-start lg:text-left mx-4 my-10 lg:w-4/5 lg:m-6">
      <h1 className="lg:text-6xl text-3xl mb-8 font-bold text-white">
        {props.data.title}
      </h1>
      <p className="lg:text-base mb-8 lg:mb-24 text-white lg:w-9/12 md:w-3/4">
        {props.data.description}
      </p>
      <Link
        to="/#contact"
        className="btn-primary lg:hidden block w-3/4 md:w-1/3 text-center"
      >
        <p className="w-full font-medium">PIŠITE NAM</p>
      </Link>
    </div>
    <img
      src={props.data.picture.url}
      alt={props.data.title}
      className="lg:h-80vh h-80 ml-10 rounded-tl-150px object-cover w-full"
    />
    <CompanyNavigation data={props.pages} />
  </div>
);

HeroContainer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    picture: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  pages: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default HeroContainer;
