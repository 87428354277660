import React from 'react';
import PropTypes from 'prop-types';

import HeroContainer from '../../elements/HeroContainer';
import AboutUsContainer from '../../elements/HotelElements/AboutUsContainer';
import OfferContainer from '../../elements/BetonaraElements/OfferContainer';
import WelcomeContainer from '../../elements/BetonaraElements/WelcomeContainer';
import GalleryContainer from '../../elements/GalleryContainer';
import ContactForm from '../../elements/ContactForm';

const BetonaraSection = (props) => (
  <>
    <HeroContainer data={props.data.heroComponent} pages={props.global.pages} />
    <AboutUsContainer data={props.data.aboutUs} />
    <OfferContainer data={props.data.offerSection} />
    <WelcomeContainer data={props.data.welcomeComponent} />
    <GalleryContainer data={props.data.galleryComponent} />
    <ContactForm data={props.data.contactForm} />
  </>
);

BetonaraSection.propTypes = {
  global: PropTypes.shape({
    pages: PropTypes.shape({
      links: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    heroComponent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    aboutUs: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      leftContent: PropTypes.string.isRequired,
      rightContent: PropTypes.string.isRequired,
    }).isRequired,
    offerSection: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      fraction1: PropTypes.string.isRequired,
      fraction2: PropTypes.string.isRequired,
      fraction3: PropTypes.string.isRequired,
      fraction4: PropTypes.string.isRequired,
      fraction5: PropTypes.string.isRequired,
      fraction6: PropTypes.string.isRequired,
    }).isRequired,
    welcomeComponent: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      unitName: PropTypes.string.isRequired,
      unitAddress: PropTypes.string.isRequired,
      unitLed: PropTypes.string.isRequired,
      workHours: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    galleryComponent: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    contactForm: PropTypes.shape({
      header: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      placeholderName: PropTypes.string.isRequired,
      placeholderEmail: PropTypes.string.isRequired,
      placeholderSubject: PropTypes.string.isRequired,
      placeholderMessage: PropTypes.string.isRequired,
      buttonSuccess: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default BetonaraSection;
