import React from 'react';
import PropTypes from 'prop-types';
import { VscChevronRight } from 'react-icons/vsc';

import './sliderArrow.scss';

const NextArrow = (props) => (
  <div
    onClick={props.onClick}
    role="presentation"
    className="next-slider-arrow"
  >
    <VscChevronRight />
  </div>
);

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  onClick: () => {},
};

export default NextArrow;
