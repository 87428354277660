import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Sections from '../components/sections';
import Seo from '../components/seo';

const DynamicPage = (props) => {
  const { contentSections, metadata } = props.data.strapiPage;
  const global = props.data.strapiGlobal;
  return (
    <>
      <Seo seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...props.pageContext }}>
        <Sections sections={contentSections} global={global} />
      </Layout>
    </>
  );
};

DynamicPage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape().isRequired,
  metadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    shareImage: PropTypes.shape({
      localFile: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

DynamicPage.defaultProps = {
  metadata: {
    title: '',
    description: '',
    shareImage: {
      localFile: {
        publicURL: '',
      },
    },
  },
};

export default DynamicPage;

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    footer {
      smallText
      facebookUrl
      instagramUrl
      twitterUrl
      logo {
        url
      }
    }
    pages {
      links {
        url
        text
      }
    }
    metadata {
      metaDescription
      metaTitle
      shareImage {
        url
      }
    }
    navbar {
      button {
        text
        url
      }
      links {
        url
        text
        id
      }
      logo {
        url
      }
    }
  }

  query DynamicPageQuery($id: String!) {
    strapiGlobal {
      ...GlobalData
    }
    strapiPage(id: { eq: $id }) {
      slug
      shortName
      metadata {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      contentSections
    }
  }
`;
