/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { sendForm } from 'emailjs-com';

import Select from './SelectSubject';

const ContactForm = (props) => {
  const [successText, setSuccessText] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const onSubmit = () => {
    sendForm(
      'service_egcse6q',
      'template_fn0y7hp',
      '#contact-form',
      'user_ZYi6sgDeDREyGtSfb6ypN',
    );
    setSuccessText(true);
    reset();
    setTimeout(() => {
      setSuccessText(false);
    }, 2000);
  };

  return (
    <>
      <div id="contact" className="header-wrapper lg:mt-16 mt-6">
        <h1 className="header-text">{props.data.header}</h1>
        <p className="title-text font-bold ourTeam-title">{props.data.title}</p>
        <p className="description-text">{props.data.description}</p>
      </div>
      <div className="lg:mb-24 mx-8 lg:flex lg:flex-col lg:justify-center lg:items-center">
        <form
          id="contact-form"
          onSubmit={handleSubmit(onSubmit)}
          className="lg:w-3/5 w-full"
        >
          <div className="lg:flex lg:justify-between">
            <input
              name="name"
              type="text"
              className="contact-input lg:mr-4"
              placeholder={props.data.placeholderName}
              {...register('name', {
                required: { value: true },
              })}
            />
            <input
              name="email"
              type="text"
              className="contact-input"
              placeholder={props.data.placeholderEmail}
              {...register('e-mail', {
                required: { value: true },
                pattern: emailPattern,
              })}
            />
          </div>
          <input
            name="subject"
            type="text"
            className="contact-input lg:w-full"
            placeholder={props.data.placeholderSubject}
            {...register('theme', {
              required: { value: true },
            })}
          />
          <Select
            name="subject"
            options={[
              'SEED d.o.o.',
              'Betonara',
              'Hotel Sana',
              'Zgrada SEED',
              'Kamenolom',
              'Trgovina SEED',
              'Trgovina Edo S.Most',
            ]}
          />
          <textarea
            name="text"
            className="resize-none h-36 placeholder-black mb-4 lg:mb-8 p-4 lg:p-5 text-sm border w-full focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            placeholder={props.data.placeholderMessage}
            {...register('message', {
              required: { value: true },
              maxLength: 600,
            })}
          />
          <div className="lg:flex lg:justify-start">
            <button type="submit" className="btn-primary mb-8">
              {`${
                successText
                  ? `${props.data.buttonSuccess}`
                  : `${props.data.buttonText}`
              }`}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

ContactForm.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    placeholderName: PropTypes.string.isRequired,
    placeholderEmail: PropTypes.string.isRequired,
    placeholderSubject: PropTypes.string.isRequired,
    placeholderMessage: PropTypes.string.isRequired,
    buttonSuccess: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactForm;
