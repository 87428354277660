/* eslint-disable no-bitwise */
import React from 'react';
import PropTypes from 'prop-types';

import { borderRadiuses, icons } from './constants';

import '../ourTeam.scss';

const randomProperty = (obj) => {
  const keys = Object.keys(obj);
  return obj[keys[Math.round((keys.length - 1) * Math.random())]];
};

const MemberCard = (props) => (
  <div className="contact-card lg:flex lg:flex-col lg:justify-center lg:mb-12 sm:mb-6 mb-4">
    <div
      className="img-wrapper"
      style={{ borderRadius: randomProperty(borderRadiuses) }}
    >
      <img className="h-400px md:h-auto" src={props.image} alt={props.name} />
    </div>
    <div>
      <p className="font-bold lg:text-2xl lg:mt-8 mt-4">{props.name}</p>
      <p className="lg:text-xl lg:mb-2 mb-2">{props.position}</p>
      <img src={randomProperty(icons)} alt="icons" className="-ml-4 h-6 mt-2" />
      <p className="lg:text-base font-light lg:mt-2 mt-2 mb-1">{props.email}</p>
      <p className="lg:text-base font-light">{props.phone}</p>
    </div>
  </div>
);

MemberCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default MemberCard;
