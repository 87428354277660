import React from 'react';
import PropTypes from 'prop-types';

const OfferContainer = (props) => (
  <div className="container-wrapper lg:mb-16 mt-8">
    <p className="header-text lg:mb-8">{props.data.header}</p>
    <div className="lg:grid lg:grid-cols-2 lg:gap-x-28">
      <div>
        <p className="title-text lg:m-0 font-bold lg:text-4xl">
          {props.data.title}
        </p>
      </div>
      <div className="block md:flex md:flex-wrap lg:flex lg:flex-wrap p-12 font-bold bg-lightBlue rounded-40px lg:text-center md:text-center">
        <div className="lg:w-1/3 md:w-1/3">
          <p>{props.data.fraction1}</p>
          <p className="mt-2">{props.data.fraction2}</p>
        </div>
        <div className="lg:w-1/3 md:w-1/3">
          <p className="mt-2 md:mt-0 lg:mt-0">{props.data.fraction3}</p>
          <p className="mt-2">{props.data.fraction4}</p>
        </div>
        <div className="lg:w-1/3 md:w-1/3">
          <p className="mt-2 md:mt-0 lg:mt-0">{props.data.fraction5}</p>
          <p className="mt-2">{props.data.fraction6}</p>
        </div>
      </div>
    </div>
  </div>
);

OfferContainer.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    fraction1: PropTypes.string.isRequired,
    fraction2: PropTypes.string.isRequired,
    fraction3: PropTypes.string.isRequired,
    fraction4: PropTypes.string.isRequired,
    fraction5: PropTypes.string.isRequired,
    fraction6: PropTypes.string.isRequired,
  }).isRequired,
};

export default OfferContainer;
