import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ seo, global }) => {
  const fullSeo = {
    ...global,
    ...seo,
  };
  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle,
        },
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          property: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        },
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl = fullSeo.metadata.shareImage.url;
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        },
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      });
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metadata.metaTitle}
      titleTemplate={`%s | ${fullSeo.metaTitle}`}
      meta={metaTags}
      link={[
        {
          rel: 'icon',
        },
      ]}
    />
  );
};

SEO.propTypes = {
  global: PropTypes.shape({
    metaData: PropTypes.shape({
      metaDescription: PropTypes.string.isRequired,
      metaTitle: PropTypes.string.isRequired,
    }),
  }).isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string.isRequired,
    metaTitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default SEO;
