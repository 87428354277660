import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Footer = (props) => (
  <div className="bg-darkBlue lg:h-60vh xl:px-36 lg:px-16 px-8 py-8 lg:py-0 lg:items-center">
    <div className="lg:flex lg:justify-between lg:items-center lg:my-24">
      <Link to="/">
        <img
          src={props.data.logo.url}
          alt="logo"
          className="lg:w-56 w-36 mb-8 lg:mb-0"
        />
      </Link>
      <div className="lg:mb-8">
        <h1 className="text-base text-gray-400 uppercase tracking-widest lg:mb-4 hidden lg:block">
          STRANICE
        </h1>
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-32 lg:gap-y-2">
          {props.pagesData.links.map((item) => (
            <Link to={item.url} key={item.text}>
              <p className="lg:text-xl text-base text-white mb-1 lg:mb-0">
                {item.text}
              </p>
            </Link>
          ))}
        </div>
      </div>
      <div className="hidden lg:block">
        {props.navbarData.links.map((item) => (
          <Link to={item.url} key={item.text}>
            <p className="lg:text-xl text-base lg:mt-2 text-white">
              {item.text}
            </p>
          </Link>
        ))}
      </div>
    </div>
    <div>
      <hr className="border-t-1 border-gray-400 hidden lg:block" />
      <p className="text-base text-gray-400 mt-4 lg:mt-14">
        Copyright © Seed 2021. All Rights Reserved.
      </p>
    </div>
  </div>
);

Footer.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    facebookUrl: PropTypes.string.isRequired,
    instagramUrl: PropTypes.string.isRequired,
    twitterUrl: PropTypes.string.isRequired,
  }).isRequired,
  pagesData: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  navbarData: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default Footer;
