import React from 'react';
import PropTypes from 'prop-types';
import { VscChevronLeft } from 'react-icons/vsc';
import './sliderArrow.scss';

const PrevArrow = (props) => (
  <div
    onClick={props.onClick}
    role="presentation"
    className="prev-slider-arrow"
  >
    <VscChevronLeft />
  </div>
);

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  onClick: () => {},
};

export default PrevArrow;
